import "@rainbow-me/rainbowkit/styles.css";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHunters, loaderonoff, Hunters } from "../../store/Items/actions";
import { usePassport, PassportProvider } from "../../context/PassportProvider";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import imxLogo from "../../assets/images/passport_logo.svg";
import axios from "axios";

const spinnerCss = css`
  display: block;
  margin: 0 auto;
`;

const BridgeTestnet = () => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { connect, disconnect, address } = usePassport();
  const dispatch = useDispatch();
  const allhunters = useSelector((state) => state.Items.allhunters);

  const fetchImxHunters = async (address) => {
    try {
      dispatch(loaderonoff(true));
      const response = await axios.get(`https://api-imx.boomland.io/api/v1/player/${address}/filteredHunters?common=1&uncommon=1&rare=1&epic=1&legendary=1&isLent=0&isBorrowed=0`);
      if (response?.data?.response) {
        dispatch(Hunters(response.data.response));
      }
    } catch (error) {
      console.error('Error fetching IMX hunters:', error);
    } finally {
      dispatch(loaderonoff(false));
    }
  };

  useEffect(() => {
    if (!address) return;
    fetchImxHunters(address);
  }, [address]);

  const bridgeToTestnet = async () => {
    try {
      setIsLoading(true);
      setError(null);

      if (!allhunters?.length) {
        setError("No hunters found to duplicate");
        setIsLoading(false);
        return;
      }

      const hunterIds = allhunters.map(hunter => hunter.details.id);

      await axios.post("https://api-imx.boomland.io/api/v1/bridge/testnet/start", {
        username: address,
        hunterIds: hunterIds,
      });

      setSuccess(true);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      setError(e?.response?.data?.message || "Internal Server Error");
    }
  };

  return (
    <div className="container-x h-full">
      <div className="sortbar">
        <h3>Duplicate Hunters to Testnet</h3>
      </div>
      <span className="border-b" />
      <div className="mt-30 flex flex-col justify-center">
        <div className="h-screen w-full px-2 self-center">
          <div className="flex flex-row justify-center w-full">
            <div className="defi-cards-bg mt-2 sm:w-[480px] sm:h-[320px] flex flex-row flex-wrap overflow-auto mr-10">
              {address && allhunters?.length
                ? allhunters.map((hunter, index) => {
                    const imgid = hunter?.details?.generatedName.slice(0, 3);
                    return (
                      <div 
                        className="w-[120px] h-[120px] p-1 border-2 border-purple-500" 
                        key={index}
                      >
                        <img
                          className="rounded-md w-full h-full"
                          src={`https://hunt-nft.cdn.boombit.cloud/Gifs/${imgid}/${hunter?.details?.generatedName}.gif`}
                        />
                      </div>
                    );
                  })
                : ""}
              {!allhunters?.length && (
                <span className="text-lg text-center mt-10 w-full self-center">
                  No Hunters found.
                </span>
              )}
            </div>

            <div className="defi-cards-bg mt-2 sm:w-[480px] flex flex-col p-4">
              <div className="defi-card-header flex justify-center align-items-center flex-col sm:flex-row mt-4">
                <button
                  className="mb-2 px-4 py-2 rounded-lg flex flex-row justify-center hover:bg-gradient-to-r hover:from-[#9734d9] hover:to-[#f5bc0c]"
                  type="button"
                  onClick={address ? disconnect : connect}
                >
                  <img
                    className="w-[32px] h-[32px] self-center mr-2"
                    src={imxLogo}
                    alt="Logo"
                  />
                  <span className="text-center self-center text-xl">
                    {address
                      ? `${address.slice(0, 10)}... ${address.slice(
                          address.length - 10,
                          address
                        )}`
                      : "Connect Passport"}
                  </span>
                </button>
              </div>

              <div className="flex flex-col justify-center mt-12">
                {error ? <span className="text-red-500">{error}</span> : null}

                {success ? (
                  <span className="text-green-500">
                    Success! Your hunters have been duplicated to testnet.
                  </span>
                ) : null}
              </div>

              <button
                className="mt-2 w-full self-end"
                onClick={bridgeToTestnet}
                disabled={success || !address || !allhunters?.length}
              >
                <div
                  className={`${
                    success || !address || !allhunters?.length
                      ? "bg-gray-500 rounded-lg p-2"
                      : "buy-btn-defi"
                  }`}
                >
                  {isLoading ? (
                    <ClipLoader css={spinnerCss} size={20} color={"#fff"} />
                  ) : (
                    "DUPLICATE TO TESTNET"
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BridgeTestnetComponent = () => (
  <PassportProvider>
    <BridgeTestnet />
  </PassportProvider>
);

export default BridgeTestnetComponent;

